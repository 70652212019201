.PlantSelector {
  position: fixed;
  top: 50%;
  left: 50%;
  pointer-events: auto;
  display: inline-block;
  box-sizing: border-box;
  transform: translate(-50%, -50%);
  width: 95%;
  height: 75%;
  z-index: 300;
  overflow-y: auto;
  background-color: #ffffff;
  border: solid 1px #000000;
  padding: 20px;
}
.Header {
  border-bottom: solid 1px #000000;
  min-height: 26px;
  background-color: #1b1534;

  padding: 12px;
}
.HeaderLabel {
  font-family: 'Siemens Sans', 'Sans Serif', Arial;
  font-size: 18px;
  line-height: 14px;
  font-weight: bold;
  /* color: rgba(0, 0, 0, 0.8); */
  color: #ffffff;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
}
.CloseIcon {
  width: 24px;
  height: 24px;
  background-size: 24px 24px;
  float: right;
  display: inline-block;
  cursor: pointer;
}
.Body {
  box-sizing: border-box;
}
.AccountNameSelect {
  border: solid 1px #a0a0b0;
  border-bottom: solid 1px #000000;
  background: linear-gradient(#d7d7df, #b3b3c3);
  min-width: 100%;
  max-width: 100%;
  height: 26px;
  width: 100%;
  font-size: 16px;
  outline: 0;
  padding: 0 4px;
  min-height: 16px;
  line-height: 20px;
  box-sizing: border-box;
}
.PlantListDiv {
  overflow-y: auto;
  height: 372px;
  box-sizing: border-box;
  display: block;
}
.PlantListLi {
  border-top: solid 1px #cccccc;
  padding: 4px;
  cursor: pointer;
  box-sizing: border-box;
}
.PlantListLi:hover {
  background-color: #dfe9f9;
}
.PlantListLiDummy {
  border-top: solid 1px #cccccc;
}
.PlantListLiSelected {
  background-color: #b0c8f0;
  border-top: solid 1px #cccccc;
  padding: 4px;
  cursor: pointer;
  box-sizing: border-box;
}
.PlantListLiToSelect {
  border: solid 1px #ff8000;
  padding: 4px;
  cursor: pointer;
  box-sizing: border-box;
}
.Footer {
  border-top: solid 1px #000000;
  min-height: 24px;
  line-height: 24px;
  padding: 12px;
  height: 56px;
  box-sizing: border-box;
  display: block;
}

.plantSelectionButton {
  height: 30px;
  min-width: 50px;
  padding: 0px 8px;
  font-family: 'Siemens Sans', 'Sans Serif', Arial;
  font-size: 14px;
  font-weight: normal;
  line-height: 100%;
  box-sizing: border-box;
  background: #1b1534;
  /* background: #49435d; */
  color: #fff;
  border: solid 1px #fff;
  float: right;
}
.plantSelectionButton:hover {
  /* background: #1b1534; */
  background: #351e7c;
  color: #fff;
}
.SelectButtonDisabled {
  height: 30px;
  min-width: 50px;
  padding: 0px 8px;
  font-family: 'Siemens Sans', 'Sans Serif', Arial;
  font-size: 14px;
  font-weight: normal;
  line-height: 100%;
  box-sizing: border-box;
  float: right;
  cursor: not-allowed;
  background: #5f5b71;
  border: solid 1px #a0a0b0;
  color: #8d8d8d;
}

.SelectButtonDisabled:hover {
  color: #8d8d8d;
}

.DropdownOption {
  font-size: 15px;
}

.SearchField {
  background-color: white;
  width: 97%;
}
.SearchContainer {
  padding-left: 2px;
}
.ps-3 {
  padding-left: 1rem !important;
}
.pe-0 {
  padding-right: 0 !important;
}
.mb-2 {
  margin-bottom: 1rem !important;
}
.d-flex {
  display: flex !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-end {
  justify-content: end !important;
}
.align-items-center {
  align-items: center !important;
}

.CustomHighlight {
  background-color: #351e7c !important;
  color: white !important;
  padding: 0 5px;
  border-radius: 5px;
}
.DefaultCell {
  padding: 0 5px;
}
