.Footer {
  display: flex;
  width: 100%;
  bottom: 0px;
  position: fixed;
  flex-direction: row;
  background: #ffffff;
  height: 3.2em;
  border: 1px solid #c4c4c4;
  text-align: center;
}
.SiemensEnergyLogo {
  height: 45%;
  margin: 0.9em 0em 0.2em 0.2em;
}
.displayFlex {
  width: 100%;
  display: flex;
  flex-direction: row; /* default value; can be omitted */
  flex-wrap: nowrap; /* default value; can be omitted */
  justify-content: space-between; /* switched from default (flex-start, see below) */
}
.logo {
  display: flex;
  margin-left: 2%;
}
.copyRight {
  font-size: 15px;
  margin: auto;
  /* float: calc(); */
}
.versionBadge {
  margin-left: 15px;
  color: #ffffff;
  float: right;
  background-color: #2b1e61;
  /* border-radius: 5px; */
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 5px 2px 5px;
  cursor: pointer;
  border-radius: 0.2em;
}
.icmaFooter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.thirdparty {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4%;
}
.thirdpartyLink {
  font-size: 12px;
  color: #8900e4;
  cursor: pointer;
}
.icmsText {
  margin-right: 5px;
  font-size: 14px;
  color: #636363;
}
.licenceText {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  color: #636363;
  top: 50%;
  margin-top: 5px;
  margin-left: 1em;
}
.customSelect::after:default :disabled::before:hover {
  background-color: #641e8c !important;
  color: #ffff !important;
  font-size: 20px;
}

.linkStyle {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
