.TermsAndConditions {
  font-size: 14px;
  position: fixed;
  z-index: 300;
  top: 50%;
  left: 50%;
  width: 1100px;
  height: 600px;
  background: #ffffff;
  border: solid 2px #808080;
  overflow-y: auto;

  word-wrap: normal;
  transform: translate(-50%, -50%);
}
.HeaderDiv {
  background-color: #c8c8d8;
  height: 50px;
}
.HeaderText {
  padding-top: 15px;
  padding-left: 10px;
  font-family: "Siemens Sans Bold", "Siemens Sans";
  font-weight: bold;
  font-size: 18px;
  color: #000000;
  opacity: 0.8;
}
.InfoContainer {
  padding-top: 10px;
  padding-left: 10px;
  box-sizing: content-box;
  font-family: "Siemens Sans", "Sans Serif", Arial;
  font-size: 16px;
  line-height: 24px;
  text-align: left;

  word-wrap: normal;
}
.Pre {
  font-family: "Siemens Sans", "Sans Serif", Arial;
  font-size: 16px;
  line-height: 24px;
  word-wrap: normal;
  white-space: pre-wrap;
}
.ButtonRow {
  position: relative;
  bottom: 0;
  width: 1076px;
  float: right;
  padding: 12px;
  border-top: solid 1px;
  box-sizing: content-box;
}
.Buttons {
  float: right;
}
.CloseButton {
  background: #c8c8d8;
  height: 30px;
  min-width: 50px;
  padding: 0px 8px;
  font-family: "Siemens Sans", "Sans Serif", Arial;
  font-size: 14px;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.8);
  line-height: 100%;
  border: solid 1px #a0a0b0;
  cursor: pointer;
  margin-right: 15px;
}
