.SideDrawer {
  position: fixed;
  width: 280px;
  max-width: 70%;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 200;
  background-color: #221d43;
  box-sizing: border-box;
  transition: transform 0.3s ease-out;
  overflow-y: auto;
}
.Open {
  transform: translateX(0);
}

.Close {
  transform: translateX(+100%);
}
