.AppNavList {
}
.AppNavList ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}
.List {
  padding: 12px;
  font-family: "Siemens Sans Bold", "Siemens Sans";
  color: white;
  list-style-type: none;
  cursor: pointer;
  background: #1b1534;
}
.List:hover,
.List:active {
  background: #251f4b;
}
.AppNavList a {
  text-decoration: none;
  color: white;
}
.AppNavList a:active,
.AppNavList a:hover,
.AppNavList a.active {
  color: white;
  background-color: #251f4b;
}
.LinkHeader {
  height: 45px;
  width: 15px;
  float: left;
  margin-left: -12px;
  margin-right: 34px;
  margin-top: -11px;
  background: #aaaaaa;
}
.ActiveHeader {
  background: #ffffff;
}
.ListActive {
  background: #251f4b;
}
