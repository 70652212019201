.VersionModel {
  font-size: 14px;
  position: fixed;
  z-index: 300;
  top: 50%;
  left: 50%;
  width: 600px;
  height: 300px;
  background: #ffffff;
  border: solid 1px #641e8c;
  border-radius: 0px;
  word-wrap: normal;
  transform: translate(-50%, -50%);
}
.HeaderDiv {
  background-color: #c8c8d8;
  height: 50px;
}
.HeaderText {
  border: solid 1px #641e8c;
  border-radius: 0px;
  padding-top: 15px;
  padding-left: 10px;
  font-family: 'Siemens Sans Bold', 'Siemens Sans';
  font-weight: bold;
  font-size: 18px;
  color: #000000;
  opacity: 0.8;
}
.customHeader {
  display: flex !important;
  border-bottom: solid 1px #641e8c !important;
  border-radius: 0px !important;
  height: 50px;
  align-items: center;
}
.iptCustomHeader {
  display: flex !important;
  justify-content: center;
  /* margin-left: 150px; */
}
.iptCustomHeaderEnd {
  display: flex !important;
  justify-content: end !important;
  /* margin-left: 100px !important; */
  justify-content: flex-end;
  align-items: center;
}
.InfoContainer {
  padding: 10px;
  box-sizing: content-box;
  font-family: 'Siemens Sans', 'Sans Serif', Arial;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  word-wrap: normal;
}
.Pre {
  font-family: 'Siemens Sans', 'Sans Serif', Arial;
  font-size: 16px;
  line-height: 24px;
  word-wrap: normal;
  white-space: pre-wrap;
}
.versionBody {
  overflow-y: auto;
  height: 190px;
  padding: 20px;
}
.customSelect::after:default :disabled::before:hover {
  background-color: #641e8c !important;
  color: #ffff !important;
  font-size: 20px;
}
.cardActionButton {
  color: black;
  margin: auto;
  /* padding-left: 15px; */
}
.versionBadge {
  margin-left: 15px;
  color: #ffffff;
  float: right;
  background-color: #2b1e61;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px 5px 10px;
  cursor: pointer;
}
.CloseButton {
  width: 20px;
}
.customLoader {
  background-color: transparent !important;
}
