.dls-rp-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0);
  z-index: 100;
}
.dls-rp-popupInner {
  position: absolute;
  width: 600px;
  max-height: 300px;
  top: 30%;
  left: 31%;
  margin: auto;
  background: white;
  border: 0.1px solid #d0d0d4;
}
.dls-rp-popupInnerU {
  position: absolute;
  width: 600px;
  height: 194px;
  top: 32%;
  left: 31%;
  margin: auto;
  background: white;
  border: 0.1px solid #d0d0d4;
}
.dls-rp-popupHeader {
  background-color: #641d8c;
  color: white;
  font-size: 15px;
  padding: 12px;
}
.dls-rp-popupContent {
  font-size: 12px;
  padding: 10px 10px 10px 10px;
  max-height: 171px;
  overflow-y: auto;
}
.dls-rp-popupButtonWrapper {
  border-top: 1px solid black;
}
.dls-rp-popupButtons {
  padding: 6px;
  right: 0;
  float: right;
}
.dls-rp-buttons {
  background-color: #d0d0d4;
  color: white;
  border: none;
  padding: 8px 15px;
  width: 57px;
  height: 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
}
.dls-rp-download {
  cursor: pointer;
  background-color: #641d8c;
  color: white;
}
.dls-rp-cancel {
  cursor: pointer;
}
