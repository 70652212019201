.Backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.SelectBackdropChild {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 400;
  left: 0;
  top: 0;
  background-color: rgba(236, 5, 5, 0);
}

.SelectBackdropParent {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: rgba(236, 5, 5, 0);
}
