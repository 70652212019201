.PortalNavList {
}
.PortalNavList ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}
.List {
  padding: 13px;
  color: white;
  list-style-type: none;
  cursor: pointer;
  font-family: 'Siemens Sans Bold', 'Siemens Sans';
  background: #1b1534;
  position: relative;
}
.List:hover,
.List:active {
  background: #251f4b;
}
.PortalNavList a {
  text-decoration: none;
  color: white;
}
.PortalNavList a:active,
.PortalNavList a:hover,
.PortalNavList a.active {
  color: white;
  background-color: #251f4b;
}
.Test {
  margin-top: 3px;
  margin-right: 18px;
  float: left;
  height: 16px;
  width: 16px;
}
.Mimicbadge {
  position: absolute;
  bottom: 32px;
  right: 25px;
  background-color: blueviolet;
  padding: 0.5px 2px;
  margin: 2px;
  text-align: center;
  font-size: small;
  border-radius: 4px;
}
.Mimictext {
  left: 40px;
  font-family: 'Siemens Sans Bold', 'Siemens Sans';
}
