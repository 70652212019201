.UpperNavContainer {
  background: #1b1534 0% 0% no-repeat padding-box;
}

.UpperNav {
  height: 100px;
  display: flex;
  align-items: center;
  max-width: 1497px;
  min-width: 1080px;
  margin: auto;
}
.NavbarContainer {
  margin-left: 10px;
  min-width: 1020px;
  display: flex;
  margin: auto;
  align-items: center;
}
.ToggleDiv {
  margin-right: 1%;
  width: 131px;
}
.DrawerToggle {
  width: 25px;
  height: 100%;

  flex-flow: column;
  justify-content: space-around;
  padding: 9px 0;
  box-sizing: border-box;
  cursor: pointer;
  margin: auto;
}

.AppName {
  width: 231px;
  float: left;
  margin-left: 6px;
  font-size: 20px;
  font-weight: normal;
  color: white;
  cursor: pointer;
  font-family: "Siemens Sans Bold", "Siemens Sans";
}
.FacilityContainer {
  margin: auto;
}
.FacilityDiv {
  font-family: "Siemens Sans Bold", "Siemens Sans";
  display: flex;
  font-size: 20px;
  color: white;
  opacity: 1;
  height: 48px;
}
.FacilityName {
  font-size: 20px;
  font-weight: normal;
  color: white;
  font-family: "Siemens Sans Bold", "Siemens Sans";
  padding: 5px;
  border: 1px solid;
  border-radius: 4px;
  background: #2b1e61;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.PlantName {
  font-size: 16px;
  font-weight: normal;
  color: white;
  font-family: "Siemens Sans Bold", "Siemens Sans";
  padding: 5px;
  border: 1px solid;
  border-radius: 4px;
  background: #2b1e61;
  display: flex;
  align-items: center;
}
.Seperator {
  color: White;
  font-size: 20px;
}
.PersonalContact {
  float: right;
  height: 39px;
  width: 133px;
  margin-right: 20px;
  cursor: pointer;
}
.ContactButton {
  margin-bottom: 1px;
  background: rgb(184, 184, 200);
  padding-right: 4px;
  align-items: center;
}
.ContactHeader {
  float: left;
  margin-top: -3px;
  padding: 2px 8px;
  font-size: 12px;
  font-weight: normal;
  font-family: "Siemens Sans Bold", "Siemens Sans";
  color: rgba(255, 255, 255, 1);
}
.ArrowDiv {
  height: 22px;
}
.ContactDrop {
  font-size: 0px;
  font-family: "Siemens Sans Bold", "Siemens Sans";
  float: right;
  margin-top: 3px;
  width: 11px;
  height: 16px;
}
.ContactSpinner {
  font-size: 0px;
  font-family: "Siemens Sans Bold", "Siemens Sans";
  float: right;
  margin-top: 3px;
  width: 22px;
  height: 16px;
}
.ContactLayer {
  cursor: default;
  margin-top: -2px;
  box-sizing: border-box;
  min-width: 297px;
  float: right;
  overflow-y: auto;
  max-height: 610px;
  position: relative;
  font-family: Siemens Sans;
  z-index: 200;
  background-color: white;
  border: 1px solid rgb(184, 184, 200);
}
.ContactContent {
  font-family: "Siemens Sans", "Sans Serif", Arial;
  font-size: 16px;
  line-height: 24px;
  box-sizing: content-box;
}
.ContactDiv {
  padding: 15px 12px 0px 12px;
  border-bottom: 1px solid rgba(184, 184, 200, 1);
}
.ContactRole {
  font-family: "Siemens Sans", "Sans Serif", Arial;
  font-size: 16px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.8);
  line-height: 24px;
  box-sizing: content-box;
}
.OtherInfoDiv {
  clear: both;
  box-sizing: content-box;
  font-family: "Siemens Sans", "Sans Serif", Arial;
  font-size: 16px;
  line-height: 24px;
  padding-top: 0px;
  padding-bottom: 8px;
}
.InfoHeader {
  font-family: "Siemens Sans Roman", "Siemens Sans";
  font-size: 12px;
  opacity: 0.8;
  line-height: 16px;
  color: #000000;
  box-sizing: content-box;
  padding-top: 6px;
  width: 35px;
}
.InfoDetail {
  padding-top: 6px;
  font-family: "Siemens Sans", "Sans Serif", Arial;
  font-size: 14px;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.8);
  line-height: 16px;
  box-sizing: content-box;
  margin-left: 10px;
}
.Flex {
  display: flex;
}
.Seperator {
  margin-top: 13px;
}
.SiemensEnergyLogo {
  height: 45px;
  margin-left: 1%;
}
