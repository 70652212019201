.AdminPage {
}
.dls-ap-wrapper {
  margin-left: 300px;
}
.dls-ap-wrapper.close {
  width: 100%;
  margin-left: 0px;
  text-shadow: none !important;
  opacity: 1 !important;
}
#dls-ap-content {
  float: right;
  width: 100%;
}
.dls-ap-content > div {
  margin: 20px 10px 0 10px;
}
.dls-ap-sidebar {
  float: left;
  width: 280px;
  margin-left: -300px;
  margin-top: 20px;
}
.dls-ap-sidebar.close {
  width: 0px;
  display: none;
}
.dls-ap-cleared {
  clear: both;
}
.dls-ap-margin-bottom {
  margin-bottom: 20px;
}
.dls-ap-padding-top {
  padding-top: 20px;
}
.dls-ap-padding-10 {
  padding: 10px;
}

/* Accordion */
.dls-ap-accordion-header {
  cursor: pointer;
  border-bottom: 1px solid black;
  background-color: #e8e8ec;
  padding: 20px 0 15px 0;
}
.dls-ap-accordion-element {
  cursor: pointer;
}
.dls-ap-accordion-element:hover {
  cursor: pointer;
  /* background-color: #f1f1f1; */
  background-color: #9631cd;
  color: #fff;
  font-size: 14px;
}

.dls-ap-accordion-header {
  /* border-bottom: 1px solid black;
      background-color: #e8e8ec; */
  border: 1px solid #641e8c;
  border-radius: 0.2em;
  background-color: #fff;
  font-size: 16px;
}
.dls-ap-custom-header {
  background-color: #641e8c;
  color: #fff;
  padding: 5px;
}
.dls-ap-accordion-header:hover {
  cursor: pointer;
  background-color: #9631cd;
  color: #fff;
}
.dls-ap-accordion-element-active {
  /* background-color: #f7f7f7; */
  cursor: pointer;
  background-color: #9631cd;
  color: #fff;
}
.dls-lp-loader {
  position: relative;
  height: 50px;
  width: 100%;
  background: url(../../../assets/images/wait.gif) center no-repeat;
  /* src/assets/images/wait.gif */
}
.dls-ap-accordion-element-disabled {
  pointer-events: none;
  opacity: 0.5;
}
.dls-ap-loader {
  position: relative;
  height: 50px;
  width: 100%;
  background: url(../../../assets/images/wait.gif) center no-repeat;
}
.dls-ap-modal-window {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #b3b3b3;
  opacity: 0.5;
  z-index: 10;
}

.dls-ap-custom-header {
  background-color: #641e8c;
  color: #fff;
  padding: 5px;
}
