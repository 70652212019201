/*########## Container ##########*/
.tabview {
  height: 100%;
  margin: 0px;
  clear: both;
}
.tabview_body div {
  border: none;
  padding: 0;
  margin: 0;
}
.tabview_body {
  border: 1px solid #000000;
  background: #ffffff;
  height: auto;
  min-height: 100%;
}
.tabview_head {
  height: 24px;
  border: none;
  margin-bottom: 1px;
  padding-left: 0;
  background: none;
  min-height: 10px;
}
.tabview_head a {
  line-height: 24px;
  padding: 0px 10px 0px 10px;
  float: left;
  position: relative;
  text-decoration: none;
}
.tabview_open {
  color: #ff8040;
  background: #ffffff;
  border: solid 1px #000000;
  border-bottom: solid 1px #ffffff;
}
.tabview_closed {
  color: #ffffff;
  background: #ff8040;
  border: solid 1px #000000;
  border-bottom: solid 1px #000000;
}
.tabview_open:hover {
  color: #404040;
  background: #ffeedd;
}
.tabview_closed:hover {
  color: #808080;
  background: #ffcc99;
}
/*########## Container ##########*/

/*########## Filemanager ##########*/
.fm_folder_back,
.fm_folder,
.fm_file {
  font-weight: normal;
}
.fm_folder_back img,
.fm_folder img,
.fm_file img,
.fm_folder_back_selected img,
.fm_file_selected img,
.fm_folder_selected img {
  border: none;
  width: 16px;
  height: 16px;
}
.fm_folder_back_selected,
.fm_file_selected,
.fm_folder_selected {
  font-weight: normal;
  background: #8080c0;
  color: #000000;
}
.fm_folder_back_XXL,
.fm_folder_XXL,
.fm_file_XXL,
.fm_folder_back_XXL_selected,
.fm_file_XXL_selected,
.fm_folder_XXL_selected {
  width: 128px;
  height: 128px;
  overflow: hidden;
  float: left;
  margin: 2px;
  color: #000000;
  line-height: 128px;
  vertical-align: bottom;
}
