.OpacityZero {
  opacity: 0;
}
.SortImage {
  width: 16px !important;
  height: 16px !important;
}
.TableContainer {
  border: 1px solid rgba(66, 66, 66, 0.1);
  border-radius: 4px;
}
.TableHeader input {
  padding: 20px;
}
.TableHeader th {
  font-weight: bold;
  padding-top: 25px !important;
  padding-bottom: 15px !important;
  vertical-align: middle;
  background-color: #641d8c;
  color: white;
  border-top: 1px solid black;
}

.TableBody tr td {
  padding: 12px 0;
  cursor: pointer !important;
  vertical-align: middle;
}

.TableRow:hover {
  background-color: #d1bcdd;
}

.PaginationBar {
  width: 100% !important;
}
.PaginationTh {
  font-weight: 430 !important;
  padding: 4px 0 !important;
}
.PaginationInputBorders {
  border: 2px solid #d1d1d1 !important;
  background-color: #fff !important;
  color: #007bff !important;

  font-weight: 600 !important;
  font-size: 16px !important;
}
.PaginationSelect {
  height: 34px !important;
  width: 60px !important;
  padding-left: 15px !important;
}
.PaginationInput {
  height: 34px !important;
  width: 70px !important;
  text-align: center !important;
}

.PaginationButtons {
  width: 40px !important;
  height: 40px !important;
}
.CurrentPageSection {
  width: 140px !important;
}
.LeftPaginationButton {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-right: 1px solid #d1d1d1 !important;
}

.RightPaginationButton {
  border-top-right-radius: 5px !important;
  border-left: 1px solid #d1d1d1 !important;
  border-bottom-right-radius: 5px !important;
}
.DoubleArrowLeft {
  background-image: url('../../../assets/images/doubleArrowLeft.svg') !important;
  width: 10px;
  height: 7px;
  display: inline-block;
  vertical-align: middle;
}
.DoubleArrowRight {
  background-image: url('../../../assets/images/doubleArrowRight.svg') !important;
  width: 10px;
  height: 7px;
  display: inline-block;
  vertical-align: middle;
}

.Disable {
  cursor: not-allowed;
}

/* global filter for tables */

.GlobalFilterInput {
  width: 326px !important;
  font-size: 14px !important;
  height: 38px !important;
}
.ResetLabel {
  color: #1b1534;
  font-size: 16px;
  cursor: pointer;
  font-weight: 500;
}
.ResetLabel:hover {
  border-bottom: 1px solid #641e8c;
  cursor: pointer;
}

.GlobalFilterActionBar {
  cursor: pointer;
}
.ResetIcon {
  background-image: url('../../../assets/images/clear.svg') !important;
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
}
.mt-1 {
  margin-top: 0.25rem !important;
}
.mt-3 {
  margin-top: 1rem !important;
}
.ms-4 {
  margin-left: 1.5rem !important;
}
.ms-3 {
  margin-left: 1rem !important;
}
.ms-2 {
  margin-left: 0.5rem !important;
}
.ms-0 {
  margin-left: 0 !important;
}
.me-5 {
  margin-right: 3rem !important;
}
.me-0 {
  margin-right: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 2rem !important;
}
.ps-3 {
  padding-left: 1rem !important;
}
.pe-3 {
  padding-right: 1rem !important;
}
.pe-0 {
  padding-right: 0 !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-2 {
  padding-top: 0.5rem !important;
}
.pb-0 {
  padding-bottom: 0px !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.d-flex {
  display: flex !important;
}
.flex-row {
  flex-direction: row !important;
}
.justify-content-center {
  justify-content: center !important;
}
.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.align-items-center {
  align-items: center !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  vertical-align: top;
  border-color: #dee2e6;
}
.table tbody > tr > td {
  border-bottom: 1px solid #dedede;
}
.table-borderless {
}
.table-hover {
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  /* transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; */
}
.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.table thead > tr > th {
  border-bottom: 1px solid hsl(0, 0%, 87%) !important;
}
.table tfoot > tr > th {
  border-top: 1px solid hsl(0, 0%, 87%) !important;
}
.table tbody > tr > td {
  border-bottom: 1px solid hsl(0, 0%, 87%);
}

.na-custom-select {
  background: url('../../../assets/images/downArrowDropdown.svg') no-repeat
    right !important;
  background-size: 10px !important;
  background-position-x: calc(100% - 10px) !important;
}

.na-custom-select.form-control.is-invalid,
.na-custom-selectselect.form-control.is-valid {
  background-image: none;
}
.form-control:invalid,
.form-control.is-invalid {
  background-image: none !important;
}

/* .icma-tr-th {
  
} */
