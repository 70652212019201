.UiMock {
}
.Banner {
  margin: 10px 0;
  padding: 10px;
  border-radius: 3px 3px 3px 3px;
  color: #d8000c;
  background-color: #ffbaba;
}
.BannerText {
  margin-top: -30px;
  margin-left: 40px;
}
