.Notification {
  display: flex;
  margin: 0rem 0px 0.25rem 0px;
  color: black;
  font-family: 'Siemens Sans Bold', 'Siemens Sans';

  width: 100%;
  justify-content: space-between;
}
.BannerText {
  padding: 2px 2em 2px 10em;
  text-align: left;
}
.CloseButton {
  cursor: pointer;
  padding-right: 5em;
}

.Info {
  background: #57c1d8;
}
.Warn {
  background: #d85757;
}
