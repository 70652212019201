.Layer2Standalone {
  height: 45px;
  max-width: 1200px;
  margin: auto;
  align-items: center;
  border-bottom: solid 1px #000000;
}
.PagesContainer {
  float: left;
  padding-left: 20px;
  display: flex;
  margin-top: 4px;
}
.SettingsContainer {
  float: right;
  display: flex;
  margin-top: 14px;
}
.PageModule {
  cursor: pointer;
  display: flex;
}
.PageModuleSeperator {
  min-width: 36px;
}
.PageModuleImage {
}
.PageModuleFont {
  font-size: 14px;
  color: black;
  margin-left: 8px;
  padding-top: 15px;
  font-family: "Siemens Sans Bold", "Siemens Sans";
}
.PageModuleFontActive {
  font-size: 14px;
  color: #007fff;
  margin-left: 8px;
  padding-top: 15px;
  font-family: "Siemens Sans Bold", "Siemens Sans";
}
.IconPointer {
  cursor: pointer;
  width: 24px;
  height: 24px;
}
.RefreshButton {
  color: white;
  cursor: pointer;
  height: 0px;
}
.ToolTip {
  margin-top: -5px !important;
  padding: 3px 11px !important;
  height: 22px !important;
}
