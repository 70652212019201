.ErrorRoute {
  margin-top: 20px;
}

.mb-2 {
  margin-bottom: 1rem !important;
}
.ml-2 {
  margin-left: 2em !important;
}
.d-flex {
  display: flex !important;
  width: 70%;
}
.row {
  display: contents !important;
}

.justify-content-center {
  justify-content: center !important;
}
.justify-content-start {
  justify-content: start !important;
}

.align-items-center {
  align-items: center !important;
}
.error-badge {
  padding: 10px 0px 12px 0px;
  margin: auto;
  background-color: #9631cd;
  color: white;
  border-radius: 10px;
  /* text-shadow: 4px 2px 4px #000000; */
  box-shadow: 5px 5px 5px 5px #c2c2c2;
}
.error-badge:hover {
  background-color: #641e8c;
}
/* .error-badge-text{
    padding: 10px 0px 10px 0px;
    margin: auto;
    background-color:#ec7676;
    color: white;
} */

.text-center {
  text-align: center !important;
}
