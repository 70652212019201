.NavList {
  width: 100%;
  display: block;
}
.ContactUs {
  background: #1b1534;
  color: white;
  bottom: 0;
  width: 90%;
  margin: 1px 1px 1px 5px;
}
.ContactUs:hover,
.ContactUs:active {
  background: #251f4b;
}
.ContactUsGap {
  margin-top: 30%;
}
