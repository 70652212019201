.Layout {
  margin: auto;
}
.Container {
  max-width: 1200px;
  margin: auto;
}
.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  background: #9631cd;
  color: #fff;
  margin: 40px;
}
