.Layer1 {
  height: 45px;
  max-width: 1200px;
  margin: auto;
  align-items: center;
}
.AppName {
  float: left;
  align-items: center;
  font-weight: bold;
  font-family: "Siemens Sans Bold", "Siemens Sans";
  font-size: 18px;
  color: #000000;
  opacity: 0.8;
  padding-top: 10px;
  padding-left: 20px;
}
.Terms {
  float: right;
  align-items: center;
  font-weight: bold;
  font-family: "Siemens Sans Bold", "Siemens Sans";
  font-size: 18px;
  color: #000000;
  opacity: 0.8;
  padding-top: 10px;
  padding-right: 20px;
  display: flex;
}
.TermsIcon {
  margin-bottom: 5px;
  font-size: 24px;
  margin-right: 4px;
  cursor: pointer;
}
.IconPointer {
  cursor: pointer;
}
.ToolTip {
  margin-top: -5px !important;
  padding: 3px 11px !important;
  height: 22px !important;
}
